
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Inter:wght@400;600;700&display=swap');


html,
body,
#root,
.app{
    height: 100%;
    width: 100%;
    font-family: "Inter,sans-serif";
}

::-webkit-scrollbar{
    width: 10px;
}

::-webkit-scrollbar-track{
    background: #7a7f9d;
}

/* handle */

::-webkit-scrollbar-thumb{
    background: #21295c;
}

/* handle on Hover */
::-webkit-scrollbar-track:hover{
    background: #21295c;
}

